@mixin colour-transition() {
    transition: color 0.5s ease;
}

@mixin transition() {
    transition: all 0.5s ease;
}

@mixin clearfix() {
    display: block;
    width: 100%;
    overflow: hidden;
}