/* Include any overrides required for Bootstrap 5 here */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

.offcanvas-header {
  border-bottom: solid 1px $grey;
  background-color: $lightgrey;
}

h6 {
  padding: 0;
  margin: 0;
}

small {
  font-weight: 300;
  font-size: 90%;
}

.navbar-brand {
  font-weight: 400;
}

.btn {
  font-weight: 400;
  border-radius: 0;
  box-shadow: 0em 0.15em 0.65em 0em rgba(0,0,0,0.25);
  font-size: 14px;

  &.btn-primary {
    background-color: $mspblue;
    border-color: $mspblue;   

    &:hover {
      background-color: $mspblueHover;
    }
  }

  &.btn-success {
    background-color: $mspgreen;
    border-color: $mspgreen;
    
    &:hover {
      background-color: $mspgreenHover;
    }
  }

  &.btn-warning {
    background-color: $msporange;
    border-color: $msporange;
    
    &:hover {
      background-color: $msporangeHover;
    }
  }
}

.alert {
  font-weight: 700;

  &.alert-success {
    background-color: $mspgreen;
    color: $white;
  }

  &.alert-warning {
    background-color: $msporange;
    color: $white;
  }
}

.form-control {
  border-radius: 0;
  
  &.text-box {
    border-radius: 0;
  }
}

.mosha__toast {
  &.success {
    background-color: $mspgreen;
  }
}

.navbar {
  background-color: $mspdarkblue;
  color: $white;
}