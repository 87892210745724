.page__login {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .container__login {
        margin: 20px auto;
        padding: 20px;
        border-radius: 0;
        background-color: $white;
        text-align: left;
        box-shadow: 0em 0.15em 0.65em 0em rgba(0,0,0,0.25);

        h1 {
            text-align: center;
        }

    }
}