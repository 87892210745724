.page__dashboard {

    .language-flag {
        display: block;
        float: left;
    }

    .language-heading {
        display: block;
        float: left;
        font-size: 1.5rem;
        margin: 5px 0 0 10px;
        font-weight: 900;
    }

    .control-btns {
        .help-text {
            display: block;
            float: right;
            padding-top: 5px;
        }
    }
    
}