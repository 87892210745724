/* -------------------- Colour Palette -------------------- */
$black: #333333;
$white: #ffffff;
$lightestgrey: #f7f7f7;
$lightgrey: #f0efef;
$grey: #d6d6d6;
$lightblue: #cfe2f3;
$blue: #3d85c6;
$error: #842029;
$errorbg: #f8d7da;
$errorborder: #f5c2c7;

$mspgreen: #05d7a7;
$mspgreenHover: #059876;
$msporange: #fb8e48;
$msporangeHover: #d3763a;
$msppink: #fdb0bc;
$mspblue: #78b9fa;
$mspblueHover: #528ec9;
$mspdarkblue: #0C163A;

/* -------------------- Fonts -------------------- */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

p {
    font-family: 'Lato', sans-serif;
}

label {
    font-weight: 700;
}

/* -------------------- General -------------------- */
body {
    background-color: $lightgrey;

    &.plugin {
        background-color: $white;
    }
}

.container {

    &.content {
        padding: 20px;
        background-color: $white;
    }
}

textarea {
    resize: vertical;
}

.u-hidden {
    display: none;
}

.control-btns {
    .btn {
        margin: 0 .2rem;
    }
}

.alert {
    &.alert-small {
        padding: 10px;
    }
}

.character-count {
    font-size: 80%;
    font-weight: 700;
}

.overlimit {
    background-color: $errorbg;
    color: $error;
    border-color: $errorborder;
}

code {
    width: auto;
    overflow: hidden;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.drag-icon {
    width: 3%;
    cursor: pointer;
    flex: 0 0 auto;
    background-color: $white;
    justify-content: center;
    display:flex;
    padding: 15px 0 0 0;

    &:hover {
        background-color: $mspblue;
        color: $white;
    }

    &:active { /* When mouse is down */
        cursor: grabbing;
    }
}

.post-area {
    width: 97%;
    flex: 0 0 auto;
}

/* -------------------- Loading Spinner -------------------- */
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 20px 0;

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $grey;
        border-color: $grey transparent $grey transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* -------------------- Validation -------------------- */
.input-validation-error {
    background-color: $errorbg;
    color: $error;
    border-color: $errorborder;
}

.field-validation-error {
    padding-top: 5px;
    @include clearfix();
}

.vc-color-wrap {
    &.round {
        width: 38px !important;
        height: 38px !important;
    }
}

/* -------------------- Workflow -------------------- */

.workflow-section {
    display: block;
    left: 0;
    top: 250px;
    position: fixed;
    min-height: 200px;
    width: 250px;
    box-shadow: 3px 3px rgba(51, 51, 51, 0.1);
    border-radius: 0 10px 10px 0;
    background-color: $white;

    .workflow-container {
        padding: 20px;
        height: 100%;
        overflow: none;

        .workflow-header {

        }

        .form-check-label {
            font-size: 0.9rem;
        }
    
    }
}